import React, { useRef } from "react";
import { Link } from "gatsby";

import { handleBeforeunLoad } from "../../../utils/mixpanelEvents/mixpanelSignupFormEvent";
import { ImageMediaItemUrl } from "@types";
import SimplePracticeLogoBlue from "../../SVGIcons/SimplePracticeLogos/SimplePracticeLogoBlue";
import usePageSignupPageStore from "../../../sp-state/usePageSignupPageStore";

import styles from "./TrialSignupHeader.module.scss";
import { trackMxpEvent } from "@utils/mixpanelEvents/mixpanelEvents";

type TrialSignupHeaderProps = {
  pageLogo: ImageMediaItemUrl;
};

export const TrialSignupHeader: React.FC<TrialSignupHeaderProps> = ({
  pageLogo,
}) => {
  const headerClasses = `${styles.trial_signup_header} bluePaleBg`;
  const { timeStart, timeEnd, resetPageState } = usePageSignupPageStore(
    state => state
  );
  const pageState = useRef({ timeEnd, timeStart });

  const handleLogoClick = () => {
    trackMxpEvent("user: Logo clicked");
    handleBeforeunLoad(pageState.current);
    resetPageState();
  };

  return (
    <header data-testid="header:TrialSignupHeader" className={headerClasses}>
      <Link onClick={handleLogoClick} to="/">
        <figure className={styles.trial_signup_header_icon}>
          {pageLogo ? (
            <img
              src={pageLogo.localFile.publicURL}
              alt={pageLogo.altText}
              className="page-logo-image"
            />
          ) : (
            <SimplePracticeLogoBlue />
          )}
        </figure>
      </Link>
    </header>
  );
};

export default TrialSignupHeader;
